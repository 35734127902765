import * as React from "react"
import { Link } from "gatsby"

const ContactButton = () => {
  const url = new URL("https://docs.google.com/forms/d/1wbBFFBt0OE0qZakAbGmnTqKfttVbwoI98nux_Abf8zc/viewform");
  const params = new URLSearchParams({
    "edit_requested" : "true",
  });
  url.search = params;

  return (
    <p className="text-center">
      <Link to={url.href} target="_blank" rel="noreferrer" className="btn btn-secondary">
        お問い合わせフォームへ
      </Link>
    </p>
  )
}

export default ContactButton
