import * as React from "react"
import { Link, graphql } from "gatsby"
import moment from "moment"
import Layout from "src/components/layout"
import Seo from "src/components/seo"
import { StaticImage } from "gatsby-plugin-image"
import GooglePlayButton from "src/components/common/google_play_button";
import AppleStoreButton from "src/components/common/apple_store_button";
import ContactButton from "src/components/common/contact_button";
import {COMPANY_INFO} from "../constants/companyInfo";

const IndexPage = ({ data }) => {
  const newsList = data.allMarkdownRemark.nodes;

  return (
    <Layout>
    <Seo
      description="株式会社DanRanの公式企業サイトです。「こどもたちが自由に人生を歩む社会をつくる」をミッションに、幼児向けアプリ等の提供しています。ニュース、会社概要、採用情報、お問い合わせなどを掲載しています。"
    />

    <article>
      <section className="container top-container">
        <StaticImage
          className="main-visual"
          src="../images/common/cp_main_visual@2x.png"
          alt="こどもたちが自由に人生を歩む社会をつくる"
        />

        <p className="text-center">
          今、こどもたちには自分の人生を拓くための
          <br />
          選択肢がたくさん用意されています。
        </p>
        <p className="text-center">
          その選択肢にこどもたちが自ら気づくこと。
          <br />
          そして、自分の力で人生を歩む彼らを
          <br />
          周りの大人が応援する社会にしたい。
        </p>
        <p className="text-center">
          DanRanはデジタルとコンテンツを通じ、
          <br />
          あらゆる大人を巻き込みながら、
          <br />
          こどもたちが可能性を広げる機会を
          <br />
          つくりだしていきます。
        </p>
      </section>

      <section className="container top-container">
        <h2 className="top-h">News</h2>
        <section>
          <ul className="news-list">
            {
              newsList.map((news) => {
                const publishedDate = moment(news.frontmatter.date);
                const link = `/news${news.fields.slug}`

                return <li className="news-list__item">
                  <Link to={link}>
                      <div class="news-date">
                        <span class="posted-on">
                          <time class="entry-date published updated" datetime={publishedDate.format("YYYY-MM-DD")} >
                            {publishedDate.format("YYYY.MM.DD")}
                          </time>
                        </span>
                      </div>
                      <div class="text">{news.frontmatter.title}</div>
                  </Link>
                </li>
                })
              }
          </ul>

          <p className="spacer"></p>

          <p className="text-center">
            <Link to="/news" className="btn btn-secondary">
              もっと見る
            </Link>
          </p>
        </section>
      </section>

      <section className="container top-container">
        <h2 className="top-h">Service</h2>
        <h3 className="top-m text-center">タッチであそぼ！あかまるどれかな？</h3>
        <p>
          ポプラ社が2017年から刊行している「あかまる」シリーズ（作・<a
          href="https://kyoda.co.jp/kyodacreation/staff/20201007317/" target="_blank"
          rel="noreferrer">しみずだいすけ</a>）の、こども向けアプリを開発しています。
        </p>

        <p>
          <Link to="products/akamaru/">
            <a href="/products/akamaru">
              <StaticImage
                className="main-visual"
                src="../images/akamaru/feature-graphic.png"
                alt="あかまるアプリの紹介"
              />
            </a>
          </Link>
        </p>

        <section key="top-download-buttons">
          <p className="text-center download-buttons">
            <AppleStoreButton/>
            <GooglePlayButton/>
          </p>
        </section>

        <p className="spacer"></p>
        <p className="text-center">
          <Link to="/products" className="btn btn-secondary">
            サービス一覧
          </Link>
        </p>
      </section>

      <section className="container top-container">
        <h2 className="top-h">About Us</h2>
        <section>
          <p className="text-center">DanRanは「一家団欒」の「団欒（団らん）」から取っています。</p>
          <p className="text-center">DanRanが目指すのは、<br />
          単に家庭の中の団らんだけではなく、<br />
          家庭を超え「こどもとその周りの大人たちがともに楽しむ空間」をつくることです。</p>
          <p className="text-center">社会に参画するあらゆる大人が、<br />
          こどもたちの豊かな未来のためにともに暮らしていく
          </p>
          <p className="text-center">
          ―そんな新しい時代の新しい団らんを。それが社名に込めた思いです。
          </p>
        </section>

        <p className="spacer"></p>

        <table className="table company-info">
          <tbody>
            <tr>
              <th>会社名</th>
              <td>{COMPANY_INFO.name}</td>
            </tr>
            <tr>
              <th>所在地</th>
              <td>
                {COMPANY_INFO.postalCode}
                <br />
                {COMPANY_INFO.address}
                <br />
                {COMPANY_INFO.building}
              </td>
            </tr>
            <tr>
              <th>代表者</th>
              <td>代表取締役社長 {COMPANY_INFO.representative}</td>
            </tr>
            <tr>
              <th>資本金</th>
              <td>1億円（資本準備金含む）</td>
            </tr>
            <tr>
              <th>事業内容</th>
              <td>「タッチであそぼ！あかまるどれかな？」等、デジタルコンテンツの開発・運営など</td>
            </tr>
            <tr>
              <th>設立</th>
              <td>2022年4月4日</td>
            </tr>
            <tr>
              <th>主要株主</th>
              <td>
                <a href="https://eversense.co.jp" target="_blank" rel="noreferrer">
                  株式会社エバーセンス
                </a>
              </td>
            </tr>
            <tr>
              <th>主要取引先</th>
              <td>
                <a href="https://www.smbc.co.jp/" target="_blank" rel="noreferrer">
                  三井住友銀行
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </article>

    <article className="container top-container">
      <h2 className="top-h">Contact</h2>
      <p className="text-center">こちらよりお問い合わせください。</p>
      <ContactButton/>
    </article>
  </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}
      limit: 2
      filter: {fields: {sourceInstanceName: {eq: "news"}}}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date
          title
        }
      }
    }
  }
`;

export default IndexPage
